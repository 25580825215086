import { FC, useRef, useEffect, useCallback } from 'react'
import s from './Modal.module.css'
import FocusTrap from '@lib/focus-trap'
import { Cross } from '@components/icons'
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
  enableBodyScroll,
} from '@components/utils/bodyScrollLock'
interface ModalProps {
  className?: string
  children?: any
  onClose: () => void
  onEnter?: () => void | null
}

const Modal: FC<ModalProps> = ({ children, onClose }) => {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>

  const handleKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        return onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    const modal = ref.current

    if (modal) {
      disableBodyScroll(modal, { reserveScrollBarGap: true })
      window?.addEventListener('keydown', handleKey)
    }
    return () => {
      if (modal) {
        enableBodyScroll(modal)
      }
      clearAllBodyScrollLocks()
      window?.removeEventListener('keydown', handleKey)
    }
  }, [handleKey])

  return (
    <div
      className={`${s.root} p-0 w-full mx-auto Z-9999 relative items-baseline-top`}
    >
      <div
        className={`${s.modal} p-0 w-full error-modal`}
        role="dialog"
        ref={ref}
      >
        <FocusTrap focusFirst>
          <div className="flex flex-row justify-center">
            {children}
            <button
              onClick={() => onClose()}
              aria-label="Close panel"
              className={s.close}
            >
              <Cross className="h-6 w-6 relative -top-3" />
            </button>
          </div>
        </FocusTrap>
      </div>
    </div>
  )
}

export default Modal
